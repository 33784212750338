import React from 'react';

import AnchorList from './AnchorList';
import xIcon from './assets/x-circle.svg';


export default class Header extends React.Component {

    constructor(props) {
        super(props);

        this.lastScrollPos = 0;
        this.sectionSet = false;

        this.state = {
            shrink: props.shrink || false ,
            banner: props.banner && !(document.cookie.indexOf('banner_closed') !== -1),
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll.bind(this))
        window.addEventListener('touchmove', this.onScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll.bind(this));
        window.removeEventListener('touchmove', this.onScroll.bind(this));
    }

    onScroll() {
        if (this.sectionSet) {
            this.sectionSet = false;
            this.setState({ shrink: true });
        }
        else {
            const yOffset = window.pageYOffset < 0 ? 0 : window.pageYOffset;
            const direction = yOffset - this.lastScrollPos;

            if (direction > 0 && !this.state.shrink) {
                this.setState({ shrink: true });
            }
            else if (direction <= 0 && this.state.shrink) {
                this.setState({ shrink: false });
            }

            this.lastScrollPos = yOffset;
        }
    }

    hideBanner() {
        this.setState({ banner: false });
        document.cookie = `banner_closed=true; max-age=${60*60*24*7}`;
    }

    render() {
        const { anchors, banner, customAnchor, hamburgered, setHamburgered, logo, currentSection, setCurrentSection } = this.props;
        const { shrink, banner: showBanner } = this.state;

        return (
            <div className={`ReactMobilized__Header ${(this.props.shrink || shrink) && !hamburgered ? 'shrink' : ''}`}>
                {banner && (
                    <div className={`ReactMobilized__banner ${showBanner ? 'active' : ''}`}>
                        {banner}

                        <button onClick={() => this.hideBanner()}>
                            <img src={xIcon} alt="Close banner" />
                        </button>
                    </div>
                )}

                <div className="ReactMobilized__Header-content">
                    <div className="ReactMobilized__Header-logo">
                        {logo}
                    </div>

                    <div className={`ReactMobilized__hamburger ${hamburgered ? 'active' : ''}`} onClick={() => setHamburgered(!hamburgered)}>
                        <div className="ReactMobilized__hamburger-dot" />
                    </div>

                    <AnchorList
                        list={anchors}
                        currentSection={currentSection}
                        customAnchor={customAnchor}
                        setCurrentSection={val => (this.sectionSet = true) && setCurrentSection(val)}
                    />
                </div>
            </div>
        );
    }

};

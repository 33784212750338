import React from 'react';

export default function AnchorList({ list, currentSection, setCurrentSection, customAnchor }) {
    return (
        <div className={'ReactMobilized__AnchorList'}>
            <ul>
                {list.map((anchor, i) => (
                    <li
                        key={`${anchor.text}${anchor.href}`}
                        className={`anchor-item ${currentSection - 1 === i ? 'active' : ''}`}
                        onClick={() => setCurrentSection(i + 1)}
                    >
                        {customAnchor ? (
                            customAnchor(anchor)
                        ) : (
                            <a href={anchor.href} onClick={e => e.preventDefault()}>
                                {anchor.text}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

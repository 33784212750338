/**
 * React Mobilized
 *
 * A mobile layout component for React.
 */

import React from 'react';

import Header from './Header';
import AnchorList from './AnchorList';

import './styles.scss';


export default class Mobilized extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hamburgered: false,
            mobile: window.innerWidth < (props.breakpoint || 0),
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize.bind(this));
    }


    onResize() {
        if (window.innerWidth < this.props.breakpoint) {
            if (!this.state.mobile) {
                this.setState({ mobile: true });
            }
        }
        else if (this.state.mobile) {
            this.setState({ mobile: false });
        }
    }

    setCurrentSection(section) {
        const el = document.querySelector(this.props.anchors[section - 1].href);

        if (el) {
            const pos = window.pageYOffset + el.getBoundingClientRect().top + (this.props.scrollOffset || 0);
            window.scrollTo({ top: pos, left: 0, behavior: 'smooth' });
        }

        this.props.setCurrentSection(section);
    }

    render() {
        const { anchors, banner, customAnchor, logo, section, shrink } = this.props;
        const { hamburgered, mobile } = this.state;

        return (
                <div className={`ReactMobilized ${hamburgered ? 'hamburgered' : ''} ${mobile ? 'mobile' : ''}`}>
                        <Header
                            anchors={anchors}
                            logo={logo}
                            banner={banner}
                            currentSection={section}
                            customAnchor={customAnchor}
                            setCurrentSection={section => this.setCurrentSection(section)}
                            shrink={shrink}
                            hamburgered={hamburgered}
                            setHamburgered={hamburgered => this.setState({ hamburgered })}
                        />

                        <div className={'ReactMobilized__mobile-anchors'}>
                            <AnchorList
                                list={anchors}
                                currentSection={section}
                                customAnchor={customAnchor}
                                setCurrentSection={section => { this.setState({ hamburgered: false }); this.setCurrentSection(section); }}
                            />
                        </div>

                        <div className={'ReactMobilized__App'}>
                            {this.props.children}
                        </div>
                </div>
        );
    }
};
